import * as React from 'react';

import { Link } from 'gatsby';
import { 
  MainLayout,
  Seo,
  LiturgicSchedule,
  DailyIcon,
  RecentArticles,
  Activities,
} from '../components';
import { beginningOfDay } from '../util';


const IndexPage = () => {

  const startDate = beginningOfDay();
  const endDate = new Date(startDate.getTime() + 1000 * 60 * 60 * 24 * 7);

  return (
    <>
      <Seo title='Acasă' />
      <MainLayout>
        <article>
          <h1>Program Liturgic</h1>
          <LiturgicSchedule 
            startDate={startDate} 
            endDate={endDate}
            />
          <p className="my-3">
            <b><Link to="/program-liturgic">Program Liturgic complet</Link></b>
          </p>
          <Activities />
        </article>
        <aside>
          <DailyIcon />
          <RecentArticles />
        </aside>
      </MainLayout>
    </>
  );
};

export default IndexPage;

